import React, { useRef } from "react";
import Header from "../Header/Header";
import SectionWrapper from "../common/SectionWrapper/SectionWrapper";
import TicketList from "../TicketList/TicketList";
import TimelineBlock from "../TimelineBlock/TimelineBlock";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import DreamDraw from "../DreamDraw/DreamDraw";
import CharityBlock from "../CharityBlock/CharityBlock";
import "./styles.css";

const Main = (props) => {
  const {
    prize,
    prizeId,
    raffles,
    raffle,
    isExpired,
    activeRaffles,
    subscriptionModels,
    data,
    onCTAClick,
    onLearnMoreClick,
    onLearnMoreCharityClick,
    onEnterNowClick,
    widgets,
  } = props;
  const ticketSelectorRef = useRef(null);

  const handleScroll = () => {
    if (ticketSelectorRef.current) {
      ticketSelectorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  return (
    <>
      {prizeId ? (
        <div className="app">
          <Header raffle={raffle} />
          <main>
            <Hero />
            <SectionWrapper bg="bg-1">
              <DreamDraw />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <div className="dream-draw__image">
                <picture>
                  <source
                    srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.09.2024-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.09.2024.jpg`}
                    media="(min-width: 576px)"
                  />
                  <source
                    srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.09.2024-sm-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.09.2024-sm.jpg`}
                  />
                  <img
                    src={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.09.2024.jpg`}
                    width={960}
                    height={540}
                    alt="dream draw"
                  />
                </picture>
              </div>
            </SectionWrapper>
            <SectionWrapper bg="bg-2">
              <TicketList
                reference={ticketSelectorRef}
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="light"
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <CharityBlock
                onCTAClick={onCTAClick}
                onLearnMoreClick={onLearnMoreClick}
                onLearnMoreCharityClick={onLearnMoreCharityClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-2">
              <TimelineBlock
                data={data.stepper}
                title={""}
                subtitle={""}
                widget={widgets.timelineWidget}
                onCTAClick={onCTAClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <TicketList
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="dark"
              />
            </SectionWrapper>
            <Footer insideComponent={true} widget={widgets.footerWidget} />
            {/* <MobileCTAButtonBottom
              variant='variant-2'
              // onClick={onEnterNowClick}
              onClick={handleScroll}
              currentRaffle={raffle}
              withCountdown
              raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
            /> */}
          </main>
        </div>
      ) : (
        <main className="no-prize">
          <h1>No prize</h1>
        </main>
      )}
    </>
  );
};

export default Main;
